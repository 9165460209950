import { Meta, Title } from '@solidjs/meta';
import { createAsync } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { LinkButton, Heading, Container, Page, Section } from '@troon/ui';
import { Icon } from '@troon/icons';
import { useUser } from '../../../providers/user';
import { FacilityCard } from '../../../components/facility/card';
import { ReservationCard } from '../../../components/reservation-card';
import { Grid, GridHalf } from '../../../components/layouts/grid';
import { SupportButton } from '../../../components/support';
import { gql, TroonCardSubscriptionProductType } from '../../../graphql';
import { getConfigValue } from '../../../modules/config';
import { cachedQuery } from '../../../graphql/cached-get';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { TeeTimeSearch } from '../../../components/tee-time-search';
import { UpsellAccess } from '../../../components/upsells/access';
import { Hero } from '../../../components/hero/photo';
import { TeeTimeSearchExplainer } from '../../../components/tee-time-access-explainer';
import { createContentfulRequest } from '../../api/content/[model]/_client';
import { BenefitCard } from '../../../components/benefit';
import { PopularDestination } from './_components/popular-destination';
import type { FacilityCardFragment, ReservationFragment } from '../../../graphql';

export function LoggedIn() {
	const user = useUser();
	const data = createAsync(() => getHomeData({ isLoggedIn: !!user(), zipcode: user()?.me.zipcode ?? '' }));
	const benefits = createAsync(
		async () => await (user()?.me.troonAccessProductType ? getBenefits('access-member-benefits') : undefined),
	);

	return (
		<Suspense>
			<Title>Troon | Book tee times and earn rewards at Troon golf courses</Title>
			<Meta
				name="description"
				content="Troon Rewards is the best way to book tee times at Troon courses around the world. Find exclusive rates and tee times. Earn points towards complimentary rounds of golf. Sign up today!"
			/>
			<Meta name="og:image" content={`${getConfigValue('IMAGE_HOST')}/digital/photo/og/home-image.jpg`} />
			<h1 class="sr-only">Troon - Reserve golf course tee times</h1>

			<Hero src={[...(data()?.zipcodeFacilities ?? []), ...(data()?.facilities ?? [])][0]?.metadata?.hero?.url}>
				<Heading as="p" size="h1" class="pt-24 lg:text-6xl">
					Welcome, {user()?.me.firstName}
				</Heading>
				<p class="pb-24 text-lg">Explore the world of Troon and find the perfect course for your next round.</p>
			</Hero>

			<Container>
				<Page>
					<div class="relative z-30 -mt-36 flex flex-col gap-4 rounded bg-white p-4 shadow-lg">
						<TeeTimeSearch />
						<TeeTimeSearchExplainer />
					</div>

					<Suspense>
						<Show
							when={
								data()?.nextUpcomingReservations?.reservations.length && data()?.nextUpcomingReservations?.reservations
							}
						>
							{(reservations) => (
								<Section>
									<div class="flex flex-row flex-wrap items-center justify-between">
										<Heading as="h2" size="h3">
											Upcoming reservations
										</Heading>
										<LinkButton href="/reservations">
											<span class="sr-only md:not-sr-only">
												View all <span class="sr-only">reservations</span>
											</span>
											<Icon name="chevron-right" />
										</LinkButton>
									</div>

									<ul class="grid grid-cols-1 gap-8">
										<For each={reservations() as Array<ReservationFragment>}>
											{(reservation) => (
												<li>
													<ReservationCard {...reservation} />
												</li>
											)}
										</For>
									</ul>
								</Section>
							)}
						</Show>
					</Suspense>

					<Suspense>
						<Section>
							<div class="flex flex-row flex-wrap items-center justify-between">
								<Heading as="h2" size="h3">
									<Show when={data()?.zipcodeFacilities?.length} fallback="Featured courses">
										Nearby courses
									</Show>
								</Heading>
								<LinkButton href="/courses">
									<span class="sr-only md:not-sr-only">
										View all <span class="sr-only">courses</span>
									</span>
									<Icon name="chevron-right" />
								</LinkButton>
							</div>

							<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
								<For each={[...(data()?.zipcodeFacilities ?? []), ...(data()?.facilities ?? [])].slice(0, 3)}>
									{(course) => (
										<li>
											<FacilityCard level="h3" facility={course as FacilityCardFragment} loading="lazy" />
										</li>
									)}
								</For>
							</ul>
						</Section>
					</Suspense>

					<Show when={user()?.activeTroonCardSubscription} fallback={<UpsellAccess location="logged-in home" />}>
						<Section>
							<div class="flex flex-row flex-wrap items-center justify-between">
								<Heading as="h2" size="h3">
									Troon Access Benefits
								</Heading>
								<LinkButton href="/access/benefits">
									<span class="sr-only md:not-sr-only">
										View all <span class="sr-only">Access benefits</span>
									</span>
									<Icon name="chevron-right" />
								</LinkButton>
							</div>

							<div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
								<Suspense>
									<For
										each={benefits()
											?.fields.benefits.filter(
												(benefit) =>
													benefit?.fields.accessLevel === 'Access' ||
													(user()?.me.troonAccessProductType === TroonCardSubscriptionProductType.TroonAccessPlus &&
														benefit?.fields.accessLevel === 'Access+'),
											)
											.slice(0, 3)}
									>
										{(benefit) => <BenefitCard {...benefit!.fields} />}
									</For>
								</Suspense>
							</div>
						</Section>
					</Show>

					<Section>
						<Heading as="h2" size="h3">
							Popular Destinations
						</Heading>

						<ul class="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
							<For each={data()?.featuredDestinations}>
								{(dest) => (
									<li>
										<PopularDestination {...dest} />
									</li>
								)}
							</For>
						</ul>
					</Section>

					<Grid>
						<GridHalf class="flex flex-col gap-6">
							<Heading as="h2" size="h2">
								Frequently asked questions
							</Heading>
							<p>Got questions? We’ve got answers.</p>
							<div>
								<SupportButton class="shrink" appearance="secondary">
									Contact support
								</SupportButton>
							</div>
						</GridHalf>

						<GridHalf border>
							<FrequentlyAskedQuestions id="logged-in-home-faqs" />
						</GridHalf>
					</Grid>
				</Page>
			</Container>
		</Suspense>
	);
}

const getBenefits = createContentfulRequest('benefits');

const homeQuery = gql(`
query home($isLoggedIn: Boolean!, $zipcode: String!) {
	zipcodeFacilities(zipcode: $zipcode, limit: 3) @include(if: $isLoggedIn) {
		...FacilityCard
	}
	facilities(featured: true, limit: 3) {
		...FacilityCard
	}
	nextUpcomingReservations(limit: 1) @include(if: $isLoggedIn) {
		reservations {
			...Reservation
		}
		hasMoreReservations
	}
	featuredDestinations {
		... on DestinationRegion {
			...PopularRegion
		}
		... on DestinationPlace {
			...PopularPlace
		}
	}
}`);

const getHomeData = cachedQuery(homeQuery);
